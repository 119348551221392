import * as React from 'react';
import Slider from 'react-slick';
import { BreakpointsEnum, BreakpointsEnumWithoutUnits } from '../../../helpers/breakpoints';
import { eventTracker } from '../../../helpers/EventTracker/EventTracker';
import { isBlank } from '../../../helpers/string';
import { __ } from '../../../helpers/TranslationService';
import { ITeaser } from '../../../reducers/homePage';
import { HomeGalleryAction } from './components/HomeGalleryAction';
import { HomeGalleryHeader } from './components/HomeGalleryHeader';
import { HomeGallerySlide } from './components/HomeGallerySlide';
import { CardSliderStyled } from '../../../components/Card/CardSlider';
import * as S from './styled';
import styled from '@emotion/styled';

export enum slideStylesEnum {
    regular = 'regular',
    special = 'special',
}

export type SlideEventTracker = {
    index: number;
    buttonType?: string;
    url?: string;
    label?: string;
};

interface IHomeSliderGalleryProps {
    deviceType?: string;
    subline?: string;
    currency: string;
    slides: ITeaser[];
    headline?: string;
    slideStyle: string;
    buttonType?: string;
    galleryName: string;
    fullScreen: boolean;
    background?: string;
    buttonLink?: string;
    buttonText?: string;
    slideHasHeader?: boolean;
    hasCompanyLogo?: boolean;
    slideHasDescription?: boolean;
    withAutoplay?: boolean;
}

export const getSliderSettings = (deviceType: string, withAutoplay?: boolean) => {
    if (withAutoplay) {
        return {
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            swipeToSlide: true,
            variableWidth: false,
            speed: 250,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: BreakpointsEnumWithoutUnits.xs,
                    settings: {
                        slidesToShow: 1,
                        nrOfSlides: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: BreakpointsEnumWithoutUnits.md,
                    settings: {
                        slidesToScroll: 1,
                        infinite: true,
                        slidesToShow: 2,
                    },
                },
            ],
        };
    }
    return {
        slidesToShow: deviceType === 'mobile' ? 1 : 4,
        slidesToScroll: 1,
        infinite: false,
        dots: deviceType === 'mobile',
        arrows: false,
        responsive: [
            {
                breakpoint: BreakpointsEnumWithoutUnits.xs,
                settings: {
                    nrOfSlides: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: BreakpointsEnumWithoutUnits.md,
                settings: {
                    slidesToScroll: 1,
                    infinite: true,
                    slidesToShow: 2,
                    rows: 1,
                    slidesPerRow: 2,
                },
            },
        ],
    };
};

const homeSlideEventTracker = ({ index, buttonType, url, label }: SlideEventTracker) => {
    if (buttonType) {
        eventTracker.trackG4Event('homepage_button_click', {
            button_type: buttonType,
            button_position: index + 1,
            teaser_text: label || '',
        });
    }

    if (url) {
        window.location.href = url;
    }
};

const HomeSliderGalleryStyled = styled(CardSliderStyled)`
    margin-left: 0;
    @media (min-width: ${BreakpointsEnum.sm}) {
        padding-bottom: 16px;
    }
    .slick-dots {
        text-align: center;
        right: 0;
        bottom: -48px;
        @media (min-width: ${BreakpointsEnum.xs}) {
            bottom: -36px;
        }
        @media (min-width: ${BreakpointsEnum.md}) {
            bottom: -48px;
        }
    }
    ${S.HomeSliderGallery} {
        background-color: transparent;
    }
    .slick-slide {
        margin-left: 0;
        margin-top: 0;
    }
    @media (min-width: ${BreakpointsEnum.xs}) and (max-width: ${BreakpointsEnum.md}) {
        &&& .slick-track {
            .slick-slide {
                min-width: unset;
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    margin-top: 0;
                }
                &:nth-of-type(odd) {
                    > div {
                        margin-left: 6px;
                    }
                }
                &:nth-of-type(even) {
                    > div {
                        margin-right: 6px;
                    }
                }
            }
        }
    }
`;

export const HomeSliderGallery = (props: IHomeSliderGalleryProps) => {
    const {
        deviceType,
        slides,
        subline,
        currency,
        headline,
        buttonLink,
        fullScreen,
        buttonText,
        background,
        slideStyle,
        buttonType,
        galleryName,
        slideHasHeader,
        hasCompanyLogo,
        slideHasDescription,
        withAutoplay,
    } = props;

    let sliderRef = React.useRef(null);
    const play = () => {
        sliderRef.current.slickPlay();
    };
    const pause = () => {
        sliderRef.current.slickPause();
    };
    // we need these onTouch events when users are held tap on the item to stop autoplay
    const onTouchEvents = withAutoplay
        ? {
              onTouchStart: pause,
              onTouchMove: pause,
              onTouchEnd: play,
          }
        : {};

    const sliceEndValue = withAutoplay ? undefined : 4;

    return (
        <HomeSliderGalleryStyled {...onTouchEvents}>
            <S.HomeSliderGallery
                data-testid={`${galleryName}-slider`}
                fullScreen={fullScreen}
                background={background}
                sliderStyle={slideStyle}
                withAutoplay={withAutoplay}
            >
                <div className="home-slider-gallery__inner">
                    {!isBlank(headline) && (
                        <HomeGalleryHeader headline={headline} subline={subline} />
                    )}

                    <Slider ref={sliderRef} {...getSliderSettings(deviceType, withAutoplay)}>
                        {slides
                            .slice(0, sliceEndValue)
                            .map(
                                (slide, i) =>
                                    slide && (
                                        <HomeGallerySlide
                                            galleryName={galleryName}
                                            slide={slide}
                                            currency={currency}
                                            slideStyle={slideStyle}
                                            key={`${galleryName}-${i}`}
                                            slideHasHeader={slideHasHeader}
                                            hasCompanyLogo={hasCompanyLogo}
                                            data-testid={`${galleryName}-${i}`}
                                            slideHasDescription={slideHasDescription}
                                            buttonType={buttonType}
                                            index={i}
                                            eventTracker={homeSlideEventTracker}
                                        />
                                    ),
                            )}
                    </Slider>
                </div>

                {!isBlank(buttonLink) && (
                    <HomeGalleryAction
                        link={buttonLink}
                        text={buttonText}
                        eventTracker={homeSlideEventTracker}
                        buttonType={buttonType}
                    />
                )}
            </S.HomeSliderGallery>
        </HomeSliderGalleryStyled>
    );
};
