import { format } from 'date-fns';
import * as React from 'react';
import * as vars from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { CalendarIcon } from '../Icons/CalendarIcon';
import { DatePickerModal } from './DatePickerModal';

const styles: Record<string, React.CSSProperties> = {
    separator: {
        borderRight: `1px solid ${vars.darkGray}`,
    },
    calendarIconStyle: {
        flex: '0 0 auto',
        fill: vars.mediumBlack,
    },
    dateSpan: {
        whiteSpace: 'nowrap',
        flex: '1 1 0%',
        marginRight: 5,
        minWidth: 0,
        overflow: 'hidden',
        fontSize: 14,
    },
};

const getPreviousDayUnix = (t: number): number => {
    if (typeof t !== 'number') {
        return null;
    }
    const d = new Date(t * 1000);
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate() - 1) / 1000;
};

interface IProps {
    pageType?: string;
    startDate: Date;
    endDate: Date;
    onStartDateChange: Function;
    onEndDateChange: Function;
    onDateChange(param: any[]): void;
    monthsNum: number;
    languageCode: string;
}

interface IState {
    open: boolean;
    dateFieldToBeSet: string;
}

export class SearchDatePicker extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            dateFieldToBeSet: 'startDate',
        };
    }

    open = (dateFieldToBeSet) => () => this.setState({ open: true, dateFieldToBeSet });
    close = () => {
        this.setState({ open: false });
    };

    render() {
        const {
            pageType,
            startDate,
            endDate,
            onStartDateChange,
            onEndDateChange,
            onDateChange,
            monthsNum,
        } = this.props;
        const { dateFieldToBeSet } = this.state;
        const modalProps = {
            pageType,
            startDate,
            // Passing down the previous date because the child passes down the next date.
            endDate,
            onStartDateChange,
            onEndDateChange,
            onDateChange,
            monthsNum,
            dateFieldToBeSet,
            close: this.close,
        };

        const dateFormat = __('DD.MM.YYYY', 'dreamlines').toLowerCase().replace('mm', 'MM');

        return (
            <div className="DateRangePicker onlyMobile">
                <div className="DateRangePickerInput">
                    <div className="DateInput" onClick={this.open('startDate')}>
                        <input
                            type="text"
                            readOnly
                            className="DateInput__input needsclick"
                            defaultValue={startDate ? startDate.toString() : ''}
                        />
                        <div className="DateInput__display-text">
                            <span style={styles.dateSpan}>
                                {startDate
                                    ? format(startDate, dateFormat)
                                    : __('Start date', 'dreamlines')}
                            </span>
                            <CalendarIcon width={18} height={18} style={styles.calendarIconStyle} />
                        </div>
                    </div>
                    <div style={styles.separator}></div>
                    <div className="DateInput" onClick={this.open('endDate')}>
                        <input
                            type="text"
                            readOnly
                            className="DateInput__input needsclick"
                            defaultValue={endDate ? endDate.toString() : ''}
                        />
                        <div className="DateInput__display-text">
                            <span style={styles.dateSpan}>
                                {endDate
                                    ? format(endDate, dateFormat)
                                    : __('End date', 'dreamlines')}
                            </span>
                            <CalendarIcon width={18} height={18} style={styles.calendarIconStyle} />
                        </div>
                    </div>
                </div>
                {this.state.open && <DatePickerModal {...modalProps} />}
            </div>
        );
    }
}
