import { eventTracker } from '../../helpers/EventTracker/EventTracker';
import { SlideEventTracker } from './HomeSliderGallery';

export const cardTeaserSliderEventTracker = ({ index, label }: SlideEventTracker) => {
    eventTracker.trackG4Event('homepage_button_click', {
        button_type: 'home_teaser',
        button_position: index + 1,
        teaser_text: label || '',
    });
};
