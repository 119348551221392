import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { getCurrencyString } from '../../components/PriceString';
import { config } from '../../config';
import { dream_deal_text } from '../../consts/variables';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { isBlank } from '../../helpers/string';
import { __ } from '../../helpers/TranslationService';
import { useDreamDeal } from '../../hooks/useDreamDeal';
import { IDreamDeal } from '../../reducers/homePage';

interface Props {
    side?: string;
    currency: string;
    flex: string;
    dreamDeal: IDreamDeal;
}

interface ISC_ExtraSearchComponent {
    side: string;
    flex: string;
    image?: string;
    isDreamDeal?: boolean;
}

export const SC_ExtraSearchComponent = styled.div<ISC_ExtraSearchComponent>`
    width: 280px;
    min-height: 272px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => props.flex};
    padding: 24px 10px;
    margin: 24px 0;
    @media (min-width: ${BreakpointsEnum.sm}) {
        border-radius: ${({ theme }) => theme.container.borderRadius};
        margin: 0;
    }
    ${({ image, theme }) =>
        image
            ? css`
                  background-image: url(${image});
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
              `
            : css`
                  background-color: ${theme.colors.white[100]};
              `}
    ${({ isDreamDeal, theme }) =>
        isDreamDeal
            ? css`
                  color: ${theme.colors.white[100]};
              `
            : css`
                  color: ${theme.text.color.primary};
              `}

    @media (min-width: ${BreakpointsEnum.md}) {
        width: 230px;
        ${(props) =>
            props.side === 'left'
                ? css`
                      margin-right: 20px;
                  `
                : css`
                      margin-left: 20px;
                  `}
    }
    @media (min-width: ${BreakpointsEnum.lg}) {
        width: 280px;
    }
    .dreamdeal-actions,
    .topoffer-actions{
        margin-top: 8px;
        a, button {
            display: inline-block;
            text-decoration: none;
            padding: 7px 0;
        }
    }
    .dreamdeal-price,
    .topoffer-price{
        font-size: 15px;
        b {
            font-size: 20px;
        }
    }
    .dreamdeal-content {
        max-width: 175px;
        text-align: center;
        .dreamdeal-title {
            margin-top: 0;
            padding-top: 0;
            font-size: 20px;
            line-height: 18px;
            text-align: center;
            margin-bottom: 4px;
            background-color: unset;
        }
        .dreamdeal-description {
            font-size: 14px;
            overflow: hidden;
            line-height: 18px;
            text-align: center;
            margin-bottom: 2px;
            letter-spacing: 0.6px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .dreamdeal-actions {
            a, a:hover{
                font-size: 12px;
                min-width: 150px;
                background: ${({ theme }) => theme.colors.white[100]};
                color: ${dream_deal_text};
            }
        }
    }
    .topoffer-content{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        max-width: 100%;
        .topoffer-image{
            max-height: 60px;
            max-width: 100%;
            padding: 0 14px;
        }
        .topoffer-title{
            font-size: 18px;
            line-height: 1.3;
            margin-top: 10px;
            margin-bottom: 5px;
        }
        .topoffer-description{
            margin: 0 0 8.5px;
        }
        .topoffer-description,
        .topoffer-subline{
            font-size: 14px;
        }
        .topoffer-actions{
            button{
                min-width: 210px;
            }
        }

    }
`;

const TimerContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .dreamdeal-top-counter__title {
        .dreamdeal-top-counter__number {
            padding: 0;
            margin: 10px 5px;
            letter-spacing: 7px;
            font-size: 24px !important;
            @media (min-width: ${BreakpointsEnum.lg}) {
                letter-spacing: 10px;
            }
            &::after {
                width: 1px;
            }
            > div {
                @media (min-width: ${BreakpointsEnum.lg}) {
                    transform: unset;
                }
            }
            p {
                margin: 0;
                transform: translateX(3px);
                @media (min-width: ${BreakpointsEnum.lg}) {
                    transform: translateX(4.5px);
                }
            }
        }
    }
`;

export const DreamDealHome = (props: Props) => {
    const { dreamDeal, currency, side } = props;
    const availableUntil = parseInt(`${dreamDeal.availableUntil}`, 10);
    const { showDreamdeal, timeLeft } = useDreamDeal(new Date(availableUntil * 1000));
    const componentSide = side || 'right';

    if (!showDreamdeal) {
        return null;
    }

    return (
        <SC_ExtraSearchComponent
            flex="center"
            side={componentSide}
            data-testid="dreamdeal-homepage"
            image={`${config.images}/common/dd_frame_fp.png`}
            isDreamDeal={true}
        >
            <TimerContainerDiv>
                <div className="dreamdeal-top-counter__title">
                    <p className="row">{__('Hours', 'dreamlines')}</p>
                    <div className="dreamdeal-top-counter__number">
                        <p>{(timeLeft.hours + timeLeft.days * 24).toString().padStart(2, '0')}</p>
                    </div>
                </div>
                <div className="dreamdeal-top-counter__title">
                    <p className="row">{__('Minutes', 'dreamlines')}</p>
                    <div className="dreamdeal-top-counter__number">
                        <p>{timeLeft.minutes.toString().padStart(2, '0')}</p>
                    </div>
                </div>
                <div className="dreamdeal-top-counter__title">
                    <p className="row">{__('Seconds', 'dreamlines')}</p>
                    <div className="dreamdeal-top-counter__number">
                        <p>{timeLeft.seconds.toString().padStart(2, '0')}</p>
                    </div>
                </div>
            </TimerContainerDiv>
            <div className="dreamdeal-content">
                <div className="dreamdeal-title">
                    <b>DREAM</b>DEAL
                </div>
                {!isBlank(dreamDeal.description) && (
                    <div
                        title={dreamDeal.description}
                        className="dreamdeal-description"
                        dangerouslySetInnerHTML={{
                            __html: dreamDeal.description,
                        }}
                    ></div>
                )}
                {dreamDeal.price !== 0 && (
                    <div
                        className="dreamdeal-price"
                        dangerouslySetInnerHTML={{
                            __html: __('from {{ displayPrice }} p.P.', 'dreamlines').replace(
                                '{{ displayPrice }}',
                                `<b>${getCurrencyString(dreamDeal.price, currency)}</b>`,
                            ),
                        }}
                    ></div>
                )}
                {!isBlank(dreamDeal.buttonText) && (
                    <div className="dreamdeal-actions">
                        <a href={dreamDeal.buttonLink}>
                            <b
                                dangerouslySetInnerHTML={{
                                    __html: dreamDeal.buttonText,
                                }}
                            ></b>
                        </a>
                    </div>
                )}
            </div>
        </SC_ExtraSearchComponent>
    );
};
