import React from 'react';
import { useCountdown } from './useCountdown';

export const isTwoDigitNumber = (obj: Duration) => {
    const maxTwoDigitsNumber = 99;
    return obj?.hours + obj?.days * 24 < maxTwoDigitsNumber;
};

export const useDreamDeal = (availableUntil: Date) => {
    const [renderCountdown, setRenderCountdown] = React.useState<boolean>(false);
    const timeLeft = useCountdown(availableUntil);

    React.useEffect(() => {
        setRenderCountdown(true);
    }, []);

    if (renderCountdown && !!timeLeft && isTwoDigitNumber(timeLeft)) {
        return { showDreamdeal: true, timeLeft };
    }
    return { showDreamdeal: false, timeLeft };
};
