import { intervalToDuration, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';

const useCountdown = (targetDate: Date) => {
    const countDownDate = targetDate;
    const [countDown, setCountDown] = useState(getDuration(countDownDate));

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(getDuration(countDownDate));
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    if (isBefore(countDownDate, new Date())) {
        return null;
    }

    return countDown;
};

const getDuration = (endDate) =>
    intervalToDuration({
        start: new Date(),
        end: endDate,
    });

export { useCountdown };
