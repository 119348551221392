import * as React from 'react';

interface IProps {
    countryCode?: string;
}
export class Shutdown extends React.Component<IProps, {}> {
    getMessage() {
        return `
                    <p>
                        É com grande pesar e tristeza que informamos que a Dreamlines Brasil
                        infelizmente está encerrando suas atividades e a partir 4 de março de 2021
                        não poderemos mais ajudar com sua reserva.
                    </p>
                    <p>
                        Para termos certeza de que você terá o atendimento necessário, entramos em
                        contato com todas as companhias de cruzeiro e operadores parceiros. A sua
                        reserva permanece inalterada e um atendente direto da companhia de cruzeiro/
                        operador irá em contato com você em breve para lhe atender e esclarecer
                        eventuais dúvidas.
                    </p>
                    <p>
                        Para perguntas urgentes sobre sua reserva ou remarcações, você encontrará as
                        informações dos contatos correspondentes neste <a href="https://www.dreamlines.com.br/contatos-reservas-remarcacoes">link</a>.
                    </p>
                    <p>
                        Agradecemos novamente pela confiança. Foi uma honra tê-lo como cliente e
                        contribuir com a realização das férias dos seus sonhos.
                    </p>
                    <p>Nossos sinceros cumprimentos,</p>
                    <p>Equipe Dreamlines Brasil.</p>
        `;
    }
    render() {
        return (
            <div id="shutdown">
                <div
                    className="container"
                    dangerouslySetInnerHTML={{ __html: this.getMessage() }}
                />
            </div>
        );
    }
}
