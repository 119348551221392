import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as Colors from '../../consts/variables';
import { BreakpointsEnum, WidthsEnum } from '../../helpers/breakpoints';

const flexCenter = css`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

export const HBHolder = styled.section`
    background: ${Colors.acqa};
    ${flexCenter};
    clear: both;
    min-height: 250px;
    padding: 35px 0;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    text-align: center;
    @media (max-width: ${BreakpointsEnum.sm}) {
        display: none;
    }
`;
export const ImgHolder = styled.div`
    &:before {
        background: ${Colors.acqa};
        bottom: 0;
        content: '';
        display: inline-block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
`;
export const Flex = styled.div`
    z-index: 2;
    @media (min-width: ${BreakpointsEnum.md}) {
        width: ${WidthsEnum.lg};
    }
    width: ${WidthsEnum.md};
    max-width: 90%;
    position: relative;
    display: flex;
`;

export const ShipIconWrap = styled.div`
    border: 2px solid ${Colors.white};
    border-radius: 100%;
    height: 75px;
    width: 75px;
    margin-right: 22px;
    ${flexCenter};
    .ship-icon {
        color: ${Colors.white};
        fill: ${Colors.white};
    }
`;
export const TxtHolder = styled.div`
    flex: 1;
    text-align: left;
    h2 {
        color: ${Colors.white};
        font-size: 2.167rem;
        margin-top: 17px;
        margin-bottom: 8.5px;
    }
    p {
        color: ${Colors.white};
        font-size: 1.333rem;
        margin: 0 0 8.5px;
    }
    a {
        border: 2px solid ${Colors.white};
        color: ${Colors.white};
        float: left;
        font-size: 1.166rem;
        margin-top: 10px;
        padding: 7px 15px;
        position: relative;
        text-decoration: none;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
    }
    .arrow-right-icon {
        color: ${Colors.white};
        fill: ${Colors.white};
        margin-left: 15px;
    }
`;
