import React from 'react';
import { ICruiseFilterParamValue } from '../../interfaces/ISearchApi';

const expiryInMinutes = 480; //8 hours

export const useHomePageFilters = ({
    updateFilterStoreState,
    newSearch,
}: {
    updateFilterStoreState: (filterParams: ICruiseFilterParamValue[]) => any;
    newSearch: () => Promise<void>;
}) => {
    const [filterProps, setFilterProps] = React.useState({
        dateMax: null,
        dateMin: null,
        zone: 'default',
        company: 'default',
    });

    const isDefaultFiltersState = () =>
        !filterProps.dateMax &&
        !filterProps.dateMin &&
        filterProps.zone === 'default' &&
        filterProps.company === 'default';

    const onApplyFilterProps = () => {
        const savedFilters = window.localStorage.getItem('homePageFiltersState');

        if (!savedFilters && isDefaultFiltersState()) {
            return;
        }

        const now = new Date();

        const item = {
            value: filterProps,
            expiry: now.getTime() + expiryInMinutes * 60 * 1000,
        };

        window.localStorage.setItem('homePageFiltersState', JSON.stringify(item));
    };

    const setFilter = (stateKey: string) => {
        return (param: any) => {
            setFilterProps((prevState) => ({ ...prevState, [stateKey]: param }));
            updateFilterStoreState([{ paramName: stateKey, paramValue: param }]);
        };
    };

    const setFilterArray = (stateKeys: string[]) => {
        return (params: string[]) => {
            let newState = {};
            const newParams = [];
            stateKeys.forEach((stateKey, index) => {
                const param = params[index];
                newState[stateKey] = param ?? null;
                newParams.push({ paramName: stateKey, paramValue: param ?? null });
            });
            updateFilterStoreState(newParams);
            setFilterProps((prevProps) => ({ ...prevProps, ...newState }));
        };
    };

    React.useEffect(() => {
        const previousHomeFiltersState = window.localStorage.getItem('homePageFiltersState');
        const parsedFilters = JSON.parse(previousHomeFiltersState);

        if (!parsedFilters) {
            newSearch();
            return;
        }

        const now = new Date();

        if (now.getTime() > parsedFilters.expiry) {
            // If the item is expired, remove it from storage
            localStorage.removeItem('homePageFiltersState');
            newSearch();
            return;
        }

        const storeFilterData: ICruiseFilterParamValue[] = Object.keys(parsedFilters.value).map(
            (filterKey) => ({
                paramName: filterKey,
                paramValue: parsedFilters.value[filterKey],
            }),
        );

        updateFilterStoreState(storeFilterData).then(() => {
            setFilterProps(parsedFilters.value);
        });
    }, []);

    return {
        filterProps,
        setFilter,
        setFilterArray,
        onApplyFilterProps,
    };
};
