import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

export function ShipIcon2(props: IIcon) {
    const newViewProps = '0 -420 512 512' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <path
                transform="scale(1,-1)"
                d="M104 417c0 17 14 31 31 31c14 0 25 -9 29 -22h1c13 0 23 -10 23 -23c0 -3 0 -6 -1 -9v0c8 0 14 -6 14 -14s-6 -14 -14 -14s-15 6 -15 14v1c-2 -1 -5 -1 -7 -1c-8 0 -14 3 -18 9c-4 -2 -8 -2 -12 -2c-17 0 -31 13 -31 30zM118 266h-42c-7 0 -13 -6 -13 -13v-89l130 63
l130 -63v89c0 7 -6 13 -13 13h-42zM268 280h-150v8c0 7 6 13 13 13h33l5 53h48l5 -53h33c7 0 13 -6 13 -13v-8zM193 212l193 -93l-63 -183h-10c-59 14 -104 40 -160 40c-35 0 -69 -11 -96 -23l-57 166z"
            ></path>
        </Icon>
    );
}
