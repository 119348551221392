import React from 'react';
import { ITeaser } from '../../reducers/homePage';
import { CardSlider } from './CardSlider';
import { CardTeaser } from './CardTeaser';
import { getCurrencyString } from '../PriceString';
import { BreakpointsEnum, BreakpointsEnumWithoutUnits } from '../../helpers/breakpoints';
import styled from '@emotion/styled';
import { SlideEventTracker } from '../../pages/Home/HomeSliderGallery';

interface ICardTeaserSlider {
    items: ITeaser[];
    isDesktop: boolean;
    currency: string;
    eventTracker?: ({ index, label }: SlideEventTracker) => void;
}

const teaserConfig = {
    dots: true,
    arrows: true,
    infinite: true,
    draggable: false,
    speed: 250,
    initialSlide: 0,
    swipeToSlide: true,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: BreakpointsEnumWithoutUnits.xs,
            settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: BreakpointsEnumWithoutUnits.md,
            settings: {
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: BreakpointsEnumWithoutUnits.xl,
            settings: {
                arrows: true,
                slidesToShow: 4,
            },
        },
    ],
};

const CardSliderStyled = styled(CardSlider)`
    margin-left: -10px;
    @media (min-width: ${BreakpointsEnum.md}) {
        margin-left: 0;
    }
    &&& .slick-slide {
        margin-left: 6px;
        @media (min-width: ${BreakpointsEnum.sm}) {
            margin-left: 6px;
        }
        &:first-child {
            margin-left: 0;
        }
    }
    &&& .slick-dots {
        left: 12px;
    }
`;

const CardSliderWrapper = styled.div`
    margin: 16px auto 0 auto;
    max-width: 1512px;
    margin-bottom: 69px;
    padding-bottom: 0px;
`;

export const CardTeaserSlider = ({
    items,
    isDesktop,
    currency,
    eventTracker,
}: ICardTeaserSlider) => {
    return (
        <CardSliderWrapper>
            <CardSliderStyled settings={teaserConfig} slidesToShow={isDesktop ? 4 : 1}>
                {items.map((slide, index) => {
                    const { companyLogo, label, text, image, url, price } = slide;
                    return (
                        <CardTeaser
                            key={label}
                            imageUrl={image}
                            url={url}
                            title={label}
                            description={text}
                            price={Number(price) > 0 ? getCurrencyString(price, currency) : null}
                            companyLogoUrl={companyLogo}
                            handleClick={() => eventTracker({ index, label })}
                        />
                    );
                })}
            </CardSliderStyled>
        </CardSliderWrapper>
    );
};
