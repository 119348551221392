import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { slideStylesEnum } from '.';
import {
    background_company,
    darkBlue,
    discount_color,
    mineSharf,
    price_color,
    white,
} from '../../../consts/variables';
import { BreakpointsEnum } from '../../../helpers/breakpoints';
import { isBlank } from '../../../helpers/string';

type IHomeSliderGallery = {
    fullScreen: boolean;
    background: string;
    sliderStyle: string;
    withAutoplay?: boolean;
};

export const HomeSliderGallery = styled.div<IHomeSliderGallery>`
    color: ${mineSharf};
    ${(props) =>
        !isBlank(props.background)
            ? css`
                  background-color: ${props.background};
              `
            : css`
                  background-color: white;
              `}
    ${(props) =>
        !props.fullScreen
            ? css`
                  padding: 20px 30px 30px;
                  .home-slider-gallery__inner {
                      margin: 0 auto;
                      @media (min-width: ${BreakpointsEnum.md}) {
                          max-width: 970px;
                      }
                      @media (min-width: ${BreakpointsEnum.lg}) {
                          max-width: 1140px;
                      }
                  }
              `
            : css`
                  margin: 10px auto;
                  max-width: 1590px;
              `}
    .home-slider-gallery__inner {
        ${(props) =>
            !props.withAutoplay
                ? css`
                      .slick-dots {
                          display: flex !important;
                          width: 100%;
                          position: absolute;
                          bottom: 0;
                          justify-content: center;

                          & > li button {
                              font-size: 0;
                              opacity: 0;
                          }

                          & > li {
                              &:not(:last-child) {
                                  margin-right: 5px;
                              }

                              background-color: ${white};
                              border: none;
                              height: 10px;
                              width: 10px;
                              border-radius: 50%;
                              opacity: 0.7;
                              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);

                              &.slick-active {
                                  opacity: 1;
                              }
                          }
                      }
                  `
                : null}
        
        @media (min-width: ${BreakpointsEnum.xs}) and (max-width: ${BreakpointsEnum.md}) {
            .slick-track {
                display: flex;
                flex-wrap: wrap;
                .slick-slide {
                    min-width: 50%;
                    &:nth-of-type(3),
                    &:nth-of-type(4) {
                        ${(props) =>
                            props.sliderStyle === slideStylesEnum.regular
                                ? css`
                                      margin-top: 28px;
                                  `
                                : css`
                                      margin-top: 10px;
                                  `}
                    }
                    > div {
                        margin: 0 6px;
                        > div:first-child {
                            margin-bottom: 12px;
                        }
                    }
                    &:nth-of-type(odd) {
                        > div {
                            margin-left: 0;
                        }
                    }
                    &:nth-of-type(even) {
                        > div {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        @media (min-width: ${BreakpointsEnum.md}) {
            .slick-slide {
                > div {
                    margin: 0 3px;
                }
                &:first-of-type {
                    > div {
                        margin-left: 0;
                    }
                }
                &:last-of-type {
                    > div {
                        margin-right: 0;
                    }
                }
            }
        }
    }
`;

export const HomeGallery_Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;

    h1 {
        margin: 0;
        z-index: 1;
        font-size: 28px;
        line-height: 44px;
        font-weight: 700;
        padding: 0 25px;
        position: relative;
    }

    p {
        font-size: 16px;
        margin-top: 8px;
    }
`;

type IHomeGallerySlide = {
    image: string;
    sliderStyle: string;
};

export const HomeSlideInner = styled.div<IHomeGallerySlide>`
    height: ${(props) => (props.sliderStyle === slideStylesEnum.special ? '300px' : '240px')};
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.image});
    .homeSlide__header {
        width: 100%;
        height: 25px;
        display: flex;
        margin: 0 auto;
        color: ${white};
        font-size: 15px;
        max-width: 310px;
        text-align: center;
        align-items: center;
        background: ${darkBlue};
        justify-content: center;
    }
    .home-slide__inner-title {
        color: ${white};
        text-align: center;
        position: absolute;
        left: 50%;
        text-shadow: 0 2px 7px rgba(0, 0, 0, 0.4), 0 -2px 7px rgba(0, 0, 0, 0.4),
            2px 0 7px rgba(0, 0, 0, 0.4), -2px 0 7px rgba(0, 0, 0, 0.4);
        ${(props) =>
            props.sliderStyle === slideStylesEnum.regular
                ? css`
                      top: 50%;
                      margin: 0;
                      width: calc(100% - 30px);
                      font-size: 30px;
                      font-weight: 700;
                      transform: translate(-50%, -50%);
                  `
                : css`
                      margin: 0 auto;
                      width: 100%;
                      bottom: 0;
                      padding-bottom: 10px;
                      font-size: 18px;
                      transform: translateX(-50%);
                      background: linear-gradient(rgba(8, 29, 46, 0), rgba(1, 47, 74, 0.71));
                  `}
        .home-slide__inner-title__price {
            font-size: 35px;
            font-weight: 700;
        }
        .home-slide__inner-title__link {
            color: ${white};
            text-decoration: none;
        }
    }
    .home-slide__inner-price {
        left: 0;
        bottom: 15px;
        display: flex;
        color: ${white};
        position: absolute;
        background: ${price_color};
        .home-slide__inner-price__discount {
            width: 63px;
            display: flex;
            font-size: 14px;
            font-weight: 700;
            position: relative;
            align-items: center;
            padding-right: 13px;
            justify-content: center;
            background-color: ${discount_color};
            clip-path: polygon(0 0, 50px 0, 63px 50%, 50px 100%, 0 100%, 0 0);
        }
        .home-slide__inner-price__per-person {
            width: 104px;
            padding: 5px 0;
            text-align: center;
            &.has_discount {
                margin-left: -13px;
            }
            span {
                font-size: 12px;
            }
            p {
                margin: 0;
                font-size: 18px;
            }
        }
    }
`;

export const HomeSlideInner_CompanyLogo = styled.div<IHomeGallerySlide>`
    right: 0;
    height: 45px;
    width: 176.5px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto calc(100% - 8px);
    background-color: ${background_company};
    background-image: url(${(props) => props.image});
    ${(props) =>
        props.sliderStyle === slideStylesEnum.regular
            ? css`
                  top: 15px;
              `
            : css`
                  bottom: 90px;
              `}
`;

export const HomeSlide = styled.div`
    cursor: pointer;
    > div {
        text-decoration: none;
        color: ${darkBlue};
        .home-slide__description {
            text-align: center;
            margin-top: 7px;
            padding: 0 15px;
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const HomeGallery_Action = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
`;
