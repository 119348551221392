import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { __ } from '../../helpers/TranslationService';
import { CardHeadingVariant } from './Card';

interface CardTeaser {
    imageUrl: string;
    companyLogoUrl?: string;
    title: string;
    titleVariant?: CardHeadingVariant;
    price?: string;
    currency?: string;
    url?: string;
    description?: string | ReactNode;
    handleClick?: () => void;
}

const CardTeaserStyled = styled.div`
    position: relative;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;

const CardTeaserInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    z-index: 1;
`;

const CardTeaserImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const CardTeaserOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(26, 24, 30, 0) 49.52%, #1a181e 94%);
`;

const CardTeaserCompanyLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 77px;
    height: 38px;
    padding: 4px 5px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    > img {
        max-width: 62px;
        max-height: 30px;
    }
`;

const CardTeaserTitle = styled.h3`
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin: 0 0 8px;
    color: ${({ theme }) => theme.colors.white[100]};
    text-shadow: 0px 2px 10px ${({ theme }) => theme.colors.black[100]};
`;

const CardTeaserTitleLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white[100]};
    &:hover {
        color: ${({ theme }) => theme.colors.white[100]};
        text-decoration: none;
    }
`;

const CardTeaserDescription = styled.div`
    color: ${({ theme }) => theme.colors.white[100]};
    font-size: 14px;
    line-height: 24px;
    text-shadow: 0px 4px 4px ${({ theme }) => theme.colors.black[50]};
`;

const CardTeaserPrice = styled.div`
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white[100]};
    text-shadow: 0px 2px 6px ${({ theme }) => theme.colors.black[50]};
`;

const CardTeaserPriceSum = styled.span`
    font-size: 30px;
    line-height: 32px;
    font-weight: 700;
    text-shadow: 0px 2px 6px ${({ theme }) => theme.colors.black[50]};
`;

const CardTeaserPriceWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 12px 12px;
    justify-content: space-between;
    z-index: 2;
`;

export const CardTeaser = (props: CardTeaser) => {
    const {
        imageUrl,
        companyLogoUrl,
        title,
        titleVariant,
        price,
        description,
        url,
        handleClick,
    } = props;

    const showOverlay = description || price || title;
    const onCardClicked = () => {
        handleClick?.();
        if (url) {
            window.location.href = url;
        }
    };

    return (
        <CardTeaserStyled onClick={onCardClicked} style={{ backgroundImage: `url(${imageUrl})` }}>
            {showOverlay && <CardTeaserOverlay />}

            <CardTeaserInfo>
                <CardTeaserTitle as={titleVariant}>
                    <CardTeaserTitleLink href={url}>{title}</CardTeaserTitleLink>
                </CardTeaserTitle>
                {description && <CardTeaserDescription>{description}</CardTeaserDescription>}
            </CardTeaserInfo>
            {!!price && (
                <CardTeaserPriceWrapper>
                    <CardTeaserPrice>
                        {__('from', 'dreamlines')} <CardTeaserPriceSum>{price}</CardTeaserPriceSum>{' '}
                        {__('p.P.', 'dreamlines')}
                    </CardTeaserPrice>
                    {companyLogoUrl && (
                        <CardTeaserCompanyLogo>
                            <img src={companyLogoUrl} loading="lazy" alt="Company logo" />
                        </CardTeaserCompanyLogo>
                    )}
                </CardTeaserPriceWrapper>
            )}
        </CardTeaserStyled>
    );
};
