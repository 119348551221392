import { addMonths, isAfter, isBefore, setDate, startOfMonth } from 'date-fns';
import * as React from 'react';
import * as vars from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { CrossIcon } from '../Icons/CrossIcon';
import { DatePickerMonth } from './DatePickerMonth';
import { dateTypeEventMap } from '../Calendar/helpers';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';
const classNames = require('classnames');

const styles: Record<string, React.CSSProperties> = {
    calendarWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    calendarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    calendarTabs: {
        flex: '0 0 auto',
    },
    calendarFooter: {
        flex: '0 0 auto',
    },
    months: {
        overflow: 'auto',
        flex: '1 1 auto',
    },
    resetButton: {
        marginLeft: 5,
        background: 'none',
        border: 'none',
        padding: 0,
    },
    closeButton: {
        fill: vars.lighterBlack,
        background: 'none',
        border: 'none',
        padding: 0,
    },
    crossIcon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        background: 'none',
        border: 'none',
        padding: 0,
    },
};

// Extracting the date and getting the midnight UTC timestamp
const getUnixTimeFromDate = (t: Date): number =>
    Date.UTC(t.getFullYear(), t.getMonth(), t.getDate()) / 1000;

// For getting the next day midnight as the maximum date so it can include
// all the results landing on the preceding (and selected) date.
const getNextDayUnixTimeFromDate = (t: Date): number =>
    Date.UTC(t.getFullYear(), t.getMonth(), t.getDate() + 1) / 1000;

// The calendar shows the date correctly only if the timestamp corresponds
// to local midnight time. So extracting the date and settig the time again.
export const normalizeDateForCalendar = (unixTime: number): number => {
    const d = new Date(unixTime * 1000);
    return +new Date(d.getFullYear(), d.getMonth(), d.getDate()) / 1000;
};

export const normalizeDateForCalendarSecs = (date: Date, daysToAdd: number = 0): number => {
    if (!date) return null;
    return (
        new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysToAdd).getTime() / 1000
    );
};
interface IProps {
    pageType: string;
    startDate: Date;
    endDate: Date;
    onStartDateChange: Function;
    onEndDateChange: Function;
    onDateChange(param: any[]): void;
    monthsNum: number;
    dateFieldToBeSet: string;
    close: () => void;
}

interface IState {
    startDate: Date | null;
    endDate: Date | null;
    open: boolean;
    dateFieldToBeSet: string;
    month: Date;
    weekdays: string[];
    dateNow: Date;
}

export class DatePickerModal extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate ? this.props.startDate : null,
            endDate: this.props.endDate ? this.props.endDate : null,
            open: true,
            dateFieldToBeSet: this.props.dateFieldToBeSet
                ? this.props.dateFieldToBeSet
                : this.props.startDate
                ? this.props.endDate
                    ? 'startDate'
                    : 'endDate'
                : 'startDate',
            month: startOfMonth(new Date()),
            weekdays: [
                __('Sun', 'dreamlines'),
                __('Mon', 'dreamlines'),
                __('Tue', 'dreamlines'),
                __('Wed', 'dreamlines'),
                __('Thu', 'dreamlines'),
                __('Fri', 'dreamlines'),
                __('Sat', 'dreamlines'),
            ],
            dateNow: new Date(),
        };
    }

    onDatesChange({ startDate, endDate, dateFieldToBeSet }) {
        this.setState({ startDate, endDate, dateFieldToBeSet });
    }

    setDate = (month: Date, i: number) => {
        const date = setDate(month, i);
        const { startDate, endDate, dateFieldToBeSet } = this.state;

        if (dateFieldToBeSet && this.props.pageType) {
            eventTracker.trackG4Event('search_filters', {
                page_type: this.props.pageType,
                event_type: dateTypeEventMap[dateFieldToBeSet],
            });
        }

        if (!isBefore(date, this.state.dateNow)) {
            if (dateFieldToBeSet === 'startDate') {
                const nextFieldtobeSet = !endDate ? 'endDate' : 'startDate';
                if (!endDate || isBefore(date, endDate)) {
                    this.onDatesChange({
                        startDate: date,
                        endDate,
                        dateFieldToBeSet: nextFieldtobeSet,
                    });
                } else if (!isBefore(date, endDate)) {
                    this.onDatesChange({
                        startDate: date,
                        endDate: null,
                        dateFieldToBeSet: 'endDate',
                    });
                }
            } else if (dateFieldToBeSet === 'endDate') {
                const nextFieldtobeSet = !startDate ? 'startDate' : 'endDate';
                if (!startDate || isAfter(date, startDate)) {
                    this.onDatesChange({
                        startDate,
                        endDate: date,
                        dateFieldToBeSet: nextFieldtobeSet,
                    });
                } else if (isBefore(date, startDate)) {
                    this.onDatesChange({
                        startDate: null,
                        endDate: date,
                        dateFieldToBeSet: 'startDate',
                    });
                }
            }
        }
    };

    setDateFieldToBeSet = (dateFieldToBeSet) => () => this.setState({ dateFieldToBeSet });

    reset = (e) => {
        e.preventDefault();
        this.setState({ startDate: null, endDate: null, dateFieldToBeSet: 'startDate' });
        this.props.onDateChange([null, null]);
    };

    applyFilters = () => {
        const selectedStartDate = this.state.startDate
            ? getUnixTimeFromDate(this.state.startDate)
            : null;
        const selectedEndDate = this.state.endDate ? getUnixTimeFromDate(this.state.endDate) : null;
        this.props.onDateChange([selectedStartDate, selectedEndDate]);
        this.props.close();
    };

    getMonths = () =>
        [...Array(this.props.monthsNum).keys()].map((i) => (
            <DatePickerMonth
                key={i}
                month={addMonths(this.state.month, i)}
                weekdays={this.state.weekdays}
                setDate={this.setDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dateNow={this.state.dateNow}
            />
        ));

    componentDidMount() {
        const { dateFieldToBeSet, startDate, endDate } = this.state;
        const fieldToBeFocused =
            dateFieldToBeSet === 'startDate'
                ? startDate
                    ? '.CalendarDay--selected-start'
                    : endDate
                    ? '.CalendarDay--selected-end'
                    : ''
                : endDate
                ? '.CalendarDay--selected-end'
                : startDate
                ? '.CalendarDay--selected-start'
                : '';
        if (fieldToBeFocused.length > 0) {
            const element = document.querySelector(fieldToBeFocused);
            if (element) {
                const scrollPosition = element.getBoundingClientRect().top;
                document.querySelector('.CalendarMonths').scrollTop = scrollPosition - 200;
            }
        }
    }

    render() {
        const startDateTabClasses = classNames('CalendarTabs__tab', {
            'CalendarTabs__tab--active': this.state.dateFieldToBeSet === 'startDate',
        });
        const endDateTabClasses = classNames('CalendarTabs__tab', {
            'CalendarTabs__tab--active': this.state.dateFieldToBeSet === 'endDate',
        });
        return (
            <div className="Calendar" style={styles.calendarWrapper}>
                <div className="Calendar__header" style={styles.calendarHeader}>
                    <button
                        className="Calendar__action"
                        style={styles.resetButton}
                        onClick={this.reset}
                    >
                        {__('reset', 'dreamlines')}
                    </button>
                    <strong className="Calendar__title">{__('date', 'dreamlines')}</strong>
                    <button
                        className="Calendar__action"
                        onClick={this.props.close}
                        style={styles.closeButton}
                    >
                        <CrossIcon style={styles.crossIcon} width={24} height={24} />
                    </button>
                </div>

                <div className="CalendarTabs" style={styles.calendarTabs}>
                    <div
                        onClick={this.setDateFieldToBeSet('startDate')}
                        className={startDateTabClasses}
                    >
                        {__('Start date', 'dreamlines')}
                    </div>
                    <div
                        onClick={this.setDateFieldToBeSet('endDate')}
                        className={endDateTabClasses}
                    >
                        {__('End date', 'dreamlines')}
                    </div>
                </div>

                <div className="CalendarMonths" style={styles.months}>
                    {this.getMonths()}
                </div>

                <div className="CalendarFooter" style={styles.calendarFooter}>
                    <div className="CalendarFooter__button" onClick={this.applyFilters}>
                        <span>{__('Apply', 'dreamlines')}</span>
                    </div>
                </div>
            </div>
        );
    }
}
