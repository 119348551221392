import * as React from 'react';
import { memo } from 'react';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { ShipIcon2 } from '../Icons/ShipIcon2';
import { useInView } from 'react-intersection-observer';
import * as S from './styled';
import { __ } from '../../helpers/TranslationService';
import { useTypedSelector } from '../../hooks/useTypedSelector';

const HomeBanner = () => {
    const themeTopicBanner = useTypedSelector(
        (state) => state?.homePage?.homePage?.themeTopicBanner || null,
    );
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
        // if intersection observer is not supported, we want to show image right away
        fallbackInView: true,
    });
    if (themeTopicBanner === null) {
        return null;
    }

    const { status, images, headline, subline, buttonText, buttonLink } = themeTopicBanner || {};
    const shouldRenderComponent =
        status || (!images?.desktop && !headline && !subline && !buttonLink);

    return shouldRenderComponent ? (
        <S.HBHolder ref={ref}>
            <S.Flex>
                <S.ShipIconWrap>
                    <ShipIcon2
                        width={'36px'}
                        height={'39px'}
                        viewBox={'0 0 50 51.3'}
                        className="ship-icon"
                    />
                </S.ShipIconWrap>
                <S.TxtHolder>
                    {!!headline && <h2>{headline}</h2>}
                    {!!subline && <p>{subline}</p>}
                    {!!buttonLink && (
                        <a href={buttonLink}>
                            <span>{buttonText || __('more', 'dreamlines')}</span>
                            <ArrowRightIcon
                                width={'12px'}
                                height={'16px'}
                                className="arrow-right-icon"
                            />
                        </a>
                    )}
                </S.TxtHolder>
            </S.Flex>
            {!!images?.desktop && (
                <S.ImgHolder>
                    {!!inView && <img src={images?.desktop} alt={`Dreamlines`} />}
                </S.ImgHolder>
            )}
        </S.HBHolder>
    ) : null;
};
export default memo(HomeBanner);
