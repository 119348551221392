import {
    endOfMonth,
    format,
    getDaysInMonth,
    isBefore,
    isSameDay,
    isSameMonth,
    isWithinInterval,
    setDate,
    startOfMonth,
} from 'date-fns';
import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
const classNames = require('classnames');

const styles: Record<string, React.CSSProperties> = {
    weekdayPadding: {
        height: '35px',
        lineHeight: '35px',
        flexGrow: 1,
        flexShrink: 0,
        textAlign: 'center',
    },
};

function Day(props) {
    const { i, month, startDate, endDate, dateNow } = props;
    const isOutOfRange = () => isBefore(setDate(month, i), dateNow);
    const isSelected = () => {
        if (startDate && endDate) {
            return isWithinInterval(setDate(month, i), {
                start: startDate ? startDate : null,
                end: endDate ? endDate : null,
            });
        }
        return false;
    };
    const dayWrapperClass = classNames('CalendarDay', {
        'CalendarDay--blocked-out-of-range': isOutOfRange(),
        'CalendarDay--selected': isSelected(),
    });

    const dayClasses =
        startDate && endDate && isSameDay(startDate, endDate)
            ? classNames({
                  'CalendarDay--selected-selected':
                      isSameDay(setDate(month, i), startDate) ||
                      isSameDay(setDate(month, i), endDate),
              })
            : classNames({
                  'CalendarDay--selected-start': isSameDay(setDate(month, i), startDate),
                  'CalendarDay--selected-end': isSameDay(setDate(month, i), endDate),
              });
    return (
        <div key={i} className={dayWrapperClass}>
            <div className={dayClasses}>{i}</div>
        </div>
    );
}

function SimpleDay(props) {
    const { i } = props;
    return (
        <div key={i} className="CalendarDay">
            <div>{i}</div>
        </div>
    );
}

interface IProps {
    month: Date;
    setDate(month: Date, i: number);
    startDate: Date;
    endDate: Date;
    weekdays: string[];
    dateNow: Date;
}

export class DatePickerMonth extends React.Component<IProps, {}> {
    setDate = (event) => {
        event.persist();
        if (event.target.textContent) {
            const i = parseInt(event.target.textContent, 10);
            this.props.setDate(startOfMonth(this.props.month), i);
        }
    };

    getDays = () => {
        const month = startOfMonth(this.props.month);
        const daysInMonth = getDaysInMonth(month);
        const offsetStart = month.getDay();
        const offsetEnd = 7 - ((offsetStart + daysInMonth) % 7);

        const days = [];

        if (offsetStart !== 7) {
            days.push(
                <div
                    key={0}
                    style={{ ...styles.weekdayPadding, flexBasis: `${14 * offsetStart}%` }}
                ></div>,
            );
        }

        if (
            isSameMonth(month, this.props.dateNow) ||
            (this.props.startDate &&
                this.props.endDate &&
                isWithinInterval(month, {
                    start: startOfMonth(this.props.startDate),
                    end: endOfMonth(this.props.endDate),
                })) ||
            isSameMonth(month, this.props.startDate) ||
            isSameMonth(month, this.props.endDate)
        ) {
            for (let i = 1; i <= daysInMonth; i++) {
                days.push(
                    <Day
                        key={i}
                        i={i}
                        month={month}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        dateNow={this.props.dateNow}
                    />,
                );
            }
        } else {
            for (let i = 1; i <= daysInMonth; i++) {
                days.push(<SimpleDay key={i} i={i} />);
            }
        }

        if (offsetEnd !== 7) {
            days.push(
                <div
                    key={daysInMonth + 1}
                    style={{ ...styles.weekdayPadding, flexBasis: `${14 * offsetEnd}%` }}
                ></div>,
            );
        }

        return days;
    };

    getWeekDaysNames = () =>
        this.props.weekdays.map((weekday, i) => (
            <div key={i} className="CalendarWeek__days">
                {weekday}
            </div>
        ));

    render() {
        const month = startOfMonth(this.props.month);
        return (
            <div className="CalendarMonth__wrapper">
                <div className="CalendarMonth__header">
                    <strong>{translateMonth(month)}</strong>
                </div>
                <div className="CalendarWeek__header">{this.getWeekDaysNames()}</div>
                <div
                    className={classNames('CalendarMonth__days', 'unselectable')}
                    onClick={this.setDate}
                >
                    {this.getDays()}
                </div>
            </div>
        );
    }
}

/**
 * Quick and ugly solution to translate the months.
 * date-fns expects locale, but since datePickerModal.tsx is manually translating the days and
 * we're low on time we'll do the same here (until a more cohesive localization strategy is decided)
 *
 * This assumes a typical output of months from date-fns
 */
export const translateMonth = (date: Date) => {
    const monthMap = {
        January: __('January', 'dreamlines'),
        February: __('February', 'dreamlines'),
        March: __('March', 'dreamlines'),
        April: __('April', 'dreamlines'),
        May: __('May', 'dreamlines'),
        June: __('June', 'dreamlines'),
        July: __('July', 'dreamlines'),
        August: __('August', 'dreamlines'),
        September: __('September', 'dreamlines'),
        October: __('October', 'dreamlines'),
        November: __('November', 'dreamlines'),
        December: __('December', 'dreamlines'),
    };

    const dateString = format(date, 'MMMM yyyy');

    try {
        const [month, year] = dateString.split(' ');
        if (!Object.keys(monthMap).includes(month)) {
            throw new Error(`${month} is not on the translation map`);
        }
        return `${monthMap[month]} ${year}`;
    } catch (error) {
        console.error(error, 'Could not translate the date');
        return dateString;
    }
};
