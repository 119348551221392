import * as React from 'react';
import { transformOptions } from '../../helpers/searchHelpers';
import { __ } from '../../helpers/TranslationService';
import { ICompanyCountArrayElement, ICountArrayElement } from '../../interfaces/ISearchApi';
import { SelectDropdown } from './SelectDropdown';

const style: Record<string, React.CSSProperties> = {
    wrapperStyle: {
        fontSize: 14,
    },
    containerStyle: {
        position: 'relative',
        zIndex: 4,
    },
};

interface IProps {
    zoneNids: ICountArrayElement[];
    companyNids: ICompanyCountArrayElement[];
    topCompanies: ICompanyCountArrayElement[];
    topZones: ICountArrayElement[];
    zone: string;
    company: string;
    onCompanyChange(param: any): void;
    onZoneChange(param: any): void;
    noPaddingTop?: boolean;
    forceDirection?: 'up' | 'down';
    autoSetFocus?: boolean;
    hideCompanyDropdown?: boolean;
}

export const ZoneAndCompanyInputSelection = function (props: IProps) {
    const defaultZoneOptions = { value: 'default', label: __('Any Destination', 'dreamlines') };
    const defaultCompanyOptions = { value: 'default', label: __('All Cruise Lines', 'dreamlines') };
    const { zoneNids, companyNids, topCompanies, topZones, hideCompanyDropdown } = props;
    return (
        <div style={style.containerStyle}>
            <SelectDropdown
                testSelectorName="destination-dropdown"
                noPaddingTop={props.noPaddingTop}
                wrapperStyle={style.wrapperStyle}
                nids={transformOptions(
                    defaultZoneOptions,
                    zoneNids,
                    topZones,
                    __('Top Zones', 'dreamlines'),
                    __('Any Destination', 'dreamlines'),
                )}
                onChange={props.onZoneChange}
                value={Array.isArray(props.zone) && props.zone.length > 1 ? 'default' : props.zone}
                instanceId="destination"
                searchPlaceholder="Type in a destination"
                searchable
                forceDirection={props.forceDirection}
                autoSetFocus={props.autoSetFocus}
            />
            {!hideCompanyDropdown && (
                <SelectDropdown
                    testSelectorName="company-dropdown"
                    wrapperStyle={style.wrapperStyle}
                    nids={transformOptions(
                        defaultCompanyOptions,
                        companyNids,
                        topCompanies,
                        __('Top Companies', 'dreamlines'),
                        __('All Cruise Lines', 'dreamlines'),
                    )}
                    onChange={props.onCompanyChange}
                    value={
                        Array.isArray(props.company) && props.company.length > 1
                            ? 'default'
                            : props.company
                    }
                    instanceId="company"
                    searchPlaceholder="Type in a cruise line"
                    searchable
                    forceDirection={props.forceDirection}
                    autoSetFocus={props.autoSetFocus}
                />
            )}
        </div>
    );
};
