import { fromUnixTime, isAfter } from 'date-fns';
import { default as React } from 'react';
import { connect, RootStateOrAny, useSelector } from 'react-redux';
import useWindowSize, { IViewPortSize } from '../../hooks/useViewport';
import { isDesktop as isBrowserDesktop } from '../../helpers/responsiveHelpers/responsiveHelpers';
import { doNewSearchViaUrl, setFilterParam, newSearch } from '../../actions/searchActions';
import { MainNavigationMenu } from '../../components/Header/MainNavigationMenu';
import HomeBanner from '../../components/HomeBanner/HomeBanner';
import { SearchMask } from '../../components/SearchFilters/SearchMask';
import { SearchMaskContainer } from '../../components/SearchFilters/SearchMaskContainer';
import { ZoneAndCompanyInputSelection } from '../../components/SearchFilters/ZoneAndCompanyInputSelection';
import { Shutdown } from '../../components/Shutdown/Shutdown';
import { config } from '../../config';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';
import { getFilter, transformOptions } from '../../helpers/searchHelpers';
import { isBlank } from '../../helpers/string';
import { __ } from '../../helpers/TranslationService';
import { IState } from '../../reducers';
import { DreamDealHome } from './DreamDealHome';
import { SEOHome } from './SEOHome';
import { slideStylesEnum } from './HomeSliderGallery';
import { TopOffer } from './TopOffer';
import { grey_f2 } from '../../consts/variables';
import { numberFormatter } from '../../helpers/TranslationService';

import loadable from '@loadable/component';

import { TrustBadges } from '../../components/TrustBadges/TrustBadges';
import { useHomePageFilters } from './useHomePageFilters';
import { CardTeaserSlider } from '../../components/Card/CardTeaserSlider';
import { cardTeaserSliderEventTracker } from './eventTrackerHelpers';

const HomeSliderGallery = loadable(() => import('./HomeSliderGallery'), {
    resolveComponent: (HomeSliderGallery) => HomeSliderGallery.HomeSliderGallery,
});

HomeSliderGallery.load();

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const organizationSchemaData = (url: string, logo: string) => ({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Dreamlines',
    url,
    logo,
});

const getFullUrl = (portalId: string): string => `https://www.${portalId}`;

export const dateTresholdDate = (availableUntil: string | number): Date => {
    const dateTresholdUnix =
        typeof availableUntil === 'number' ? availableUntil : parseInt(availableUntil, 10);
    if (isNaN(dateTresholdUnix)) {
        throw new Error(`Unexpected date value: ${availableUntil}`);
    }
    return fromUnixTime(dateTresholdUnix);
};

const HomePageClass = (props: Props) => {
    const { filterProps, setFilter, setFilterArray, onApplyFilterProps } = useHomePageFilters({
        updateFilterStoreState: props.setFilterParam,
        newSearch: props.newSearch,
    });

    const data = useSelector((state: RootStateOrAny) => state.homePage);

    const openOffer = (GaEvent, url: string): void => {
        eventTracker.trackG4Event('homepage_button_click', GaEvent);
        window.location.href = url;
    };

    const { zoneNids, companyNids, topCompanies, topZones } = props.search.searchFacets;
    const { dateMax, dateMin, zone, company } = getFilter(props.search, filterProps);
    const side = 'right';

    const shouldShowDreamDeal =
        data.homePage?.dreamDeal?.status &&
        isAfter(dateTresholdDate(data.homePage.dreamDeal.availableUntil), new Date());

    const numResults = props.search.searchFacets.numResults;
    const disableButton = numResults ? false : true;
    const loadingButton = !numResults && numResults !== 0;
    const numberOfFiltersUsed = props.search.filterParamsList.length;
    const showSearchBtn = numberOfFiltersUsed ? true : false;

    const searchMaskProps = {
        searchFormId: 'searchForm',
        pageType: 'Home Page',
        title:
            (Number.isInteger(numResults) ? numberFormatter().format(numResults) + ' ' : '') +
            __('Search cruise', 'dreamlines'),
        dateMax,
        dateMin,
        onDateMinChange: setFilter('dateMin'),
        onDateMaxChange: setFilter('dateMax'),
        sticky: false,
        isLoading: loadingButton,
        doNewSearchViaUrl: props.doNewSearchViaUrl,
        showSearchBtn: showSearchBtn,
        isDisabled: disableButton,
        onDateChange: setFilterArray(['dateMin', 'dateMax']),
        numberOfFiltersUsed: numberOfFiltersUsed,
        languageCode: props.pageData.appConfig.languageCode,
        countryCode: props.pageData.appConfig.countryCode,
        headline: __('Great cruises, many discounts (mobile)', 'dreamlines'),
        headlineDesktop: __('Great cruises, many discounts', 'dreamlines'),
        relativePositioning: shouldShowDreamDeal || data.homePage?.topOffer?.status,
        buttonClickEventData: {
            button_type: 'home_search',
            company_name:
                companyNids.find((companyObj) => companyObj?.id === company)?.title || company,
            destination_name: zoneNids.find((zoneObj) => zoneObj?.id === zone)?.title || zone,
        },
    };

    const zoneAndCompanyInputProps = {
        zoneNids,
        companyNids,
        topCompanies,
        topZones,
        zone,
        company,
        transformOptions: transformOptions,
        onCompanyChange: setFilter('company'),
        onZoneChange: setFilter('zone'),
        noPaddingTop: false,
    };

    const baseUrl = getFullUrl(props.portalId);
    const { width }: IViewPortSize = useWindowSize();
    const isDesktop = isBrowserDesktop(width);

    const hasTopTeasers =
        data.homePage?.topTeasers?.status && !!data.homePage?.topTeasers?.items?.length;

    const hasDestinationGalleryDesktop =
        isDesktop &&
        data.homePage?.destinationGallery?.status &&
        !!data.homePage?.destinationGallery?.items?.length;

    const hasThemeCruiseTeasersDesktop =
        isDesktop &&
        data.homePage?.themeCruiseTeasers?.status &&
        !!data.homePage?.themeCruiseTeasers?.items?.length;

    const hasCruiseLineGalleryDesktop =
        isDesktop &&
        data.homePage?.cruiseLineGallery?.status &&
        !!data.homePage?.cruiseLineGallery?.items?.length;

    const hasSEODesktop =
        isDesktop && data.homePage?.seo?.section && data.homePage?.seo?.section?.status;

    return data && props.portalId !== 'dreamlines.com.br' ? (
        <div className="home-page-container">
            <SearchMaskContainer
                side={side}
                hasDreamDeal={shouldShowDreamDeal || data.homePage?.topOffer?.status}
                image={data.homePage?.stage?.backgroundImage}
            >
                <SearchMask
                    isHomePage
                    pageData={props.pageData}
                    onAfterApplyClick={onApplyFilterProps}
                    {...searchMaskProps}
                    testSelectorName="home-page-headline"
                >
                    <ZoneAndCompanyInputSelection
                        {...zoneAndCompanyInputProps}
                        autoSetFocus={isDesktop}
                    />
                </SearchMask>
                <TrustBadges portalId={props.portalId} className="trust-badges" />
                {shouldShowDreamDeal ? (
                    <>
                        <DreamDealHome
                            side={side}
                            flex="center"
                            currency={props.pageData.appConfig.defaultCurrency}
                            dreamDeal={data.homePage.dreamDeal}
                        />

                        {!isBlank(data.homePage.dreamDeal?.buttonLink) && (
                            <div
                                className="dreamdeal-url"
                                onClick={(e) =>
                                    openOffer(
                                        {
                                            button_type: 'home_dreamdeal',
                                            cruise_id: data.homePage.dreamDeal?.cruiseId,
                                            description: data.homePage.dreamDeal?.description,
                                        },
                                        data.homePage.dreamDeal?.buttonLink,
                                    )
                                }
                            ></div>
                        )}
                    </>
                ) : data.homePage?.topOffer?.status ? (
                    <>
                        <TopOffer
                            side={side}
                            flex="start"
                            currency={props.pageData.appConfig.defaultCurrency}
                            topOffer={data.homePage.topOffer}
                        />
                        {!isBlank(data.homePage.topOffer.companyImage) && (
                            <div
                                className="topoffer-url"
                                onClick={(e) => {
                                    openOffer(
                                        {
                                            button_type: 'home_banner',
                                            headline: data.homePage.topOffer?.headline,
                                        },
                                        data.homePage.topOffer.buttonLink,
                                    );
                                }}
                            >
                                {' '}
                            </div>
                        )}
                    </>
                ) : null}
            </SearchMaskContainer>
            {hasTopTeasers && (
                <CardTeaserSlider
                    isDesktop={isDesktop}
                    items={data.homePage.topTeasers.items}
                    currency={props.pageData.brandConfig.defaultCurrency}
                    eventTracker={cardTeaserSliderEventTracker}
                />
            )}

            {hasDestinationGalleryDesktop && (
                <HomeSliderGallery
                    fullScreen={false}
                    background={grey_f2}
                    galleryName="destinationGallery"
                    slideHasDescription={true}
                    slideStyle={slideStylesEnum.regular}
                    slides={data.homePage.destinationGallery.items}
                    headline={data.homePage.destinationGallery.headline}
                    subline={data.homePage.destinationGallery?.subline}
                    buttonLink={data.homePage.destinationGallery.buttonLink}
                    buttonText={data.homePage.destinationGallery.buttonText}
                    currency={props.pageData.brandConfig.defaultCurrency}
                />
            )}
            <HomeBanner />
            {hasThemeCruiseTeasersDesktop && (
                <HomeSliderGallery
                    fullScreen={false}
                    background={grey_f2}
                    galleryName="themeCruiseTeasers"
                    slideHasDescription={true}
                    slideStyle={slideStylesEnum.regular}
                    slides={data.homePage.themeCruiseTeasers.items}
                    headline={data.homePage.themeCruiseTeasers.headline}
                    subline={data.homePage.themeCruiseTeasers?.subline}
                    buttonLink={data.homePage.themeCruiseTeasers.buttonLink}
                    buttonText={data.homePage.themeCruiseTeasers.buttonText}
                    currency={props.pageData.brandConfig.defaultCurrency}
                />
            )}
            {hasCruiseLineGalleryDesktop && (
                <HomeSliderGallery
                    fullScreen={false}
                    hasCompanyLogo={true}
                    galleryName="cruiseLineGallery"
                    slideHasDescription={true}
                    slideStyle={slideStylesEnum.regular}
                    slides={data.homePage.cruiseLineGallery.items}
                    headline={data.homePage.cruiseLineGallery.headline}
                    subline={data.homePage.cruiseLineGallery?.subline}
                    buttonLink={data.homePage.cruiseLineGallery.buttonLink}
                    buttonText={data.homePage.cruiseLineGallery.buttonText}
                    currency={props.pageData.brandConfig.defaultCurrency}
                />
            )}
            {hasSEODesktop && <SEOHome content={data?.homePage?.seo?.section?.text} />}
            <div className="onlyMobile">
                <MainNavigationMenu
                    seoContent={data?.homePage?.seo?.section?.text}
                    menuItems={props.pageData.header.mainNavigationMobile}
                    isTopNavigation={false}
                />
            </div>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(
                        organizationSchemaData(
                            baseUrl,
                            `${config.images}/${props.portalId}/logo.svg`,
                        ),
                    ),
                }}
            />
        </div>
    ) : (
        <Shutdown />
    );
};

const mapStateToProps = (state: IState) => ({
    search: state.search,
    homePage: state.homePage,
    pageData: state.pageData,
    portalId: state.pageData.appConfig.portalId,
});

const mapDispatchToProps = {
    newSearch: newSearch as any,
    setFilterParam,
    doNewSearchViaUrl,
};

export const HomePage = connect(mapStateToProps, mapDispatchToProps)(HomePageClass);

export default HomePage;
