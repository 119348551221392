import * as Sentry from '@sentry/react';
import * as http from '../../helpers/http';
import * as React from 'react';
import styled from '@emotion/styled';
import * as Colors from '../../consts/variables';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { SearchDatePicker } from '../SearchDatePicker/SearchDatePicker';
import { FilterFooter } from './FilterFooter';
import loadable from '@loadable/component';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { parseAbsoluteUtcDate } from '../../helpers/date';
import classnames from 'classnames';
import { MarketContext } from '../../context/markets';
import { Modal } from '../Modal';
import { Button } from '../Button/Button';
import { IPageDataReducer } from '../../reducers/pageData';
import { IRouterReducer } from '../../reducers/router';
import { useSearchCruise } from '../SearchCruise/useSearchCruise';

const Calendar = loadable(() => import('../Calendar/calendar'));

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        background: Colors.blue,
        padding: '1px 15px 15px 15px',
        fontSize: 20,
    },
    searchButton: {
        marginTop: 18,
    },
    headline: {
        color: Colors.white,
        fontSize: 25,
        textAlign: 'center',
        fontWeight: 'bold',
        margin: '23px 0 13px',
    },
};

interface IProps {
    children: React.ReactNode;
    pageType?: string;
    dateMax: number;
    dateMin: number;
    onDateMinChange(param: any): void;
    onDateMaxChange(param: any): void;
    onDateChange(param: any[]): void;
    doNewSearchViaUrl?: () => void;
    onAfterApplyClick?: () => void;
    sticky?: boolean;
    isLoading: boolean;
    isDisabled?: boolean;
    noPaddingTop?: boolean;
    showSearchBtn: boolean;
    trackingCategory?: string;
    numberOfFiltersUsed?: number;
    languageCode: string;
    countryCode: string;
    headline?: string;
    headlineDesktop?: string;
    testSelectorName?: string;
    relativePositioning?: boolean;
    isHomePage?: boolean;
    searchFormId?: string;
    title?: string;
    buttonClickEventData?: Record<string, string | number>;
    pageData: IPageDataReducer;
    router?: IRouterReducer;
}

const SearchMaskDiv = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.background.brandColor2};
    padding: 1px 15px 15px 15px;
    font-size: 20px;
    height: unset;
    @media (min-width: ${BreakpointsEnum.sm}) {
        width: unset;
    }

    &.relativePositioning {
        position: relative;
        z-index: ${(props) => props.theme.overlayIndexes.searchMask};
    }
    &.homePage {
        @media (min-width: ${BreakpointsEnum.sm}) {
            border-radius: ${({ theme }) => theme.container.borderRadius};
        }
        background-color: ${({ theme }) => theme.background.searchBox};
    }
`;

const SearchMaksForm = styled.form`
    margin-block-end: 0;
`;

const GotTVCode = styled.p`
    color: ${({ theme }) => theme.colors.white[100]};
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: 24px 0 0 0;
`;

const GotTVCodeLink = styled.span<{ onClick: () => void }>`
    text-decoration: underline;
    cursor: pointer;
`;

const TVOfferCodeModalWrapper = styled.div`
    width: auto;
    text-align: left;
    @media (min-width: ${BreakpointsEnum.sm}) {
        width: 508px;
    }
`;

const TVOfferCodeModalTitle = styled.h4`
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin: 0 30px 8px 0;
`;

const TVOfferCodeModalText = styled.p`
    font-size: 14px;
    margin-bottom: 24px;
`;

const StyledInput = styled.input`
    line-height: 20px;
    font-size: 15px;
    border: 1px solid ${({ theme }) => theme.colors.iron[100]};
    width: 100%;
    height: 48px;
    padding: 9px 16px;
    margin-bottom: 8px;
    outline: none;
    border-radius: ${({ theme }) => theme.container.borderRadius};
`;

export const SearchMask = (props: IProps) => {
    const { markets } = React.useContext(MarketContext);

    const { searchCruiseUsingFilterParams, searchId, handleInputChange } = useSearchCruise({
        countryCode: props.pageData.appConfig.countryCode,
        apiHost: props.pageData.appConfig.apiHost,
        brandConfig: props.pageData.brandConfig,
    });

    const [isOpen, setIsOpen] = React.useState(false);
    const [isSearchingOffer, setIsSearchingOffer] = React.useState(false);

    const { isCruise1stUK } = markets;

    const classes = classnames('searchMask', {
        relativePositioning: props.relativePositioning,
        homePage: props.isHomePage,
    });

    const handleOfferCodeSearch = () => {
        const offerCode = searchId.trim();

        if (!offerCode) {
            return;
        }

        setIsSearchingOffer(true);

        const filterParams = {
            offset: 0,
            limit: 1,
            filterParams: {
                tvOfferCodes: [offerCode],
            },
        };

        searchCruiseUsingFilterParams(filterParams)
            .then((cruise) => {
                props.router.history.push(cruise.cruisePageUrl);
            })
            .catch(() => {
                const slugs = props.pageData.brandConfig.slugsObj;
                props.router.history.push(slugs.mediaOffer);
            })
            .finally(() => {
                setIsSearchingOffer(false);
            });
    };

    const handleApplyFiltersClick = () => {
        props.onAfterApplyClick?.();
        props.doNewSearchViaUrl?.();
    };

    return (
        <>
            <SearchMaskDiv className={classes}>
                {props.headline && (
                    <h1
                        style={style.headline}
                        {...TestSelector.getSelector(props.testSelectorName)}
                    >
                        <span
                            className={props.headlineDesktop ? 'onlyMobile' : ''}
                            dangerouslySetInnerHTML={{ __html: props.headline }}
                        ></span>
                        {props.headlineDesktop && (
                            <span className={'onlyTabletUp'}>{props.headlineDesktop}</span>
                        )}
                    </h1>
                )}
                <SearchMaksForm id={props.searchFormId}>
                    {props.children}
                    <SearchDatePicker
                        pageType={props.pageType}
                        startDate={parseAbsoluteUtcDate(props.dateMin * 1000)}
                        endDate={parseAbsoluteUtcDate(props.dateMax * 1000)}
                        onStartDateChange={props.onDateMinChange}
                        onEndDateChange={props.onDateMaxChange}
                        onDateChange={props.onDateChange}
                        monthsNum={24}
                        languageCode={props.languageCode}
                    />
                    <Calendar
                        startDate={parseAbsoluteUtcDate(props.dateMin * 1000)}
                        endDate={parseAbsoluteUtcDate(props.dateMax * 1000)}
                        onDateChange={props.onDateChange}
                        pageType={props.pageType}
                        countryCode={props.countryCode}
                    />
                    {props.showSearchBtn ? (
                        <div style={style.searchButton}>
                            <FilterFooter
                                title={props.title}
                                titleDesktop={__('Find offers', 'dreamlines')}
                                doNewSearchViaUrl={handleApplyFiltersClick}
                                sticky={props.sticky}
                                isLoading={props.isLoading}
                                isDisabled={props.isDisabled}
                                testSelectorName="searchButton"
                                buttonClickEventData={props.buttonClickEventData}
                            />
                        </div>
                    ) : (
                        <div style={style.searchButton}>
                            <FilterFooter
                                title={props.title}
                                titleDesktop={__('Find offers', 'dreamlines')}
                                doNewSearchViaUrl={handleApplyFiltersClick}
                                sticky={props.sticky}
                                isLoading={props.isLoading}
                                isDisabled={false}
                                testSelectorName="searchButton"
                            />
                        </div>
                    )}
                </SearchMaksForm>
                {isCruise1stUK && props.isHomePage && (
                    <GotTVCode>
                        {__('Got a TV offer code?', 'dreamlines')}{' '}
                        <GotTVCodeLink onClick={() => setIsOpen(true)}>
                            {__('Click here', 'dreamlines')}
                        </GotTVCodeLink>
                    </GotTVCode>
                )}
            </SearchMaskDiv>
            {isCruise1stUK && props.isHomePage && isOpen && (
                <Modal isOpen mobileBottom onClose={() => setIsOpen(false)}>
                    <TVOfferCodeModalWrapper>
                        <TVOfferCodeModalTitle>
                            Got a TV offer reference number?
                        </TVOfferCodeModalTitle>
                        <TVOfferCodeModalText>
                            You can watch our TV channel on Sky Channel 681
                        </TVOfferCodeModalText>
                        <StyledInput
                            placeholder="eg: INDY04"
                            value={searchId}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                        <Button
                            fullWidth
                            color="primary"
                            onClick={handleOfferCodeSearch}
                            loading={isSearchingOffer}
                        >
                            {__('Apply', 'dreamlines')}
                        </Button>
                    </TVOfferCodeModalWrapper>
                </Modal>
            )}
        </>
    );
};
