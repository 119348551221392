import React, { useEffect } from 'react';
import { CrossIcon } from './Icons/CrossIcon';
import { softBlack50 } from '../consts/variables';
import classnames from 'classnames';
interface IProps {
    className?: string;
    isOpen: boolean;
    onClose: React.EventHandler<any>;
    children: JSX.Element;
    style?: React.CSSProperties;
    mobileBottom?: boolean;
}

export const Modal = (props: IProps) => {
    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    const { className, style, children, mobileBottom, onClose } = props;

    const classes = classnames(`modalContainer ${className || ''}`, {
        mobilePositionBottom: mobileBottom,
    });

    return (
        <div className={classes}>
            <div className="modalOverlay" onClick={onClose} />
            <div style={style} className="modalContent">
                <button onClick={onClose} className="modalClose">
                    <CrossIcon
                        style={{
                            width: '20px',
                            height: '20px',
                            fill: softBlack50,
                        }}
                    />
                </button>
                {children}
            </div>
        </div>
    );
};
