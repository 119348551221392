import * as React from 'react';
import { isBlank } from '../../../../helpers/string';
import * as S from './../styled';
interface IHomeGalleryHeader {
    headline: string;
    subline?: string;
}

export const HomeGalleryHeader = (props: IHomeGalleryHeader) => {
    const { headline, subline } = props;
    return (
        <S.HomeGallery_Header>
            <h1
                dangerouslySetInnerHTML={{
                    __html: headline,
                }}
            ></h1>
            {!isBlank(subline) && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: subline,
                    }}
                ></p>
            )}
        </S.HomeGallery_Header>
    );
};
