import * as propTypes from 'prop-types';
import * as React from 'react';
import { TestSelector } from '../../helpers/TestSelector';
import loadable from '@loadable/component';

const CustomSelect = loadable(() => import('../CustomSelect/CustomSelect'));

const styleSelect: any = {
    marginTop: '10px',
    fontSize: '14px',
};

interface IProps {
    nids: any;
    value: string;
    onChange: (value: any) => void;
    onChangeCountry?: (value: any) => void;
    onChangeHarbour?: (value: any) => void;
    noPaddingTop?: boolean;
    instanceId?: string; //used for server side render and for action for tracking also
    searchable?: boolean;
    searchPlaceholder?: string;
    wrapperStyle?: any;
    testSelectorName?: string;
    forceDirection?: 'up' | 'down';
    autoSetFocus?: boolean;
    setIsActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

export class SelectDropdown extends React.Component<IProps> {
    static contextTypes = {
        track: propTypes.func,
    };

    onChange = (val) => {
        if (val) {
            const { value, type } = val;

            if (type) {
                val.type === 'harbour'
                    ? this.props.onChangeHarbour(value)
                    : this.props.onChangeCountry(value);
            } else {
                this.props.onChange(value);
            }

            if (this.context.track) {
                this.context.track({
                    action: this.props.instanceId,
                    label: val.label.replace(/ \((.*)\)$/, ''), // remove `(num)` from label
                });
            }
        }
    };

    render() {
        const { props } = this;
        const wrapperStyle = props.noPaddingTop ? { ...styleSelect, marginTop: 0 } : styleSelect;
        const containerStyle: any = props.wrapperStyle
            ? {
                  ...wrapperStyle,
                  ...props.wrapperStyle,
              }
            : wrapperStyle;
        const placeholder =
            props.nids.length === 1 && props.nids[0].value === 'default' ? props.nids[0].label : '';
        return (
            <div style={containerStyle} {...TestSelector.getSelector(this.props.testSelectorName)}>
                <CustomSelect
                    value={props.value}
                    options={props.nids}
                    onChange={this.onChange}
                    resetValue="default"
                    clearable={false}
                    searchable={props.searchable}
                    placeholder={placeholder}
                    roundedSelect
                    searchPlaceholder={props.searchPlaceholder}
                    forceDirection={props.forceDirection}
                    autoSetFocus={props.autoSetFocus}
                    setIsActive={props.setIsActive}
                />
            </div>
        );
    }
}
