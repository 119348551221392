import * as React from 'react';
import * as vars from '../../consts/variables';
import { getCurrencyString } from '../../components/PriceString';
import { Button } from '../../components/Button/Button';
import { isBlank } from '../../helpers/string';
import { __ } from '../../helpers/TranslationService';
import { ITopOffer } from '../../reducers/homePage';
import { SC_ExtraSearchComponent } from './DreamDealHome';

interface Props {
    flex: string;
    side?: string;
    currency: string;
    topOffer: ITopOffer;
}
export const TopOffer = (props: Props) => {
    const { topOffer, side, currency } = props;
    const componentSide = side || 'right';
    return (
        <SC_ExtraSearchComponent
            flex="start"
            side={componentSide}
            data-testid="topoffer-homepage"
            isDreamDeal={false}
        >
            <div className="topoffer-content">
                {!isBlank(topOffer.companyImage) && (
                    <img
                        className="topoffer-image"
                        alt={`${topOffer.headline} company logo`}
                        src={topOffer.companyImage}
                    />
                )}
                {!isBlank(topOffer.headline) && (
                    <div className="topoffer-title">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: topOffer.headline,
                            }}
                        ></b>
                    </div>
                )}
                {!isBlank(topOffer.subtitle) && (
                    <div
                        className="topoffer-description"
                        dangerouslySetInnerHTML={{
                            __html: topOffer.subtitle,
                        }}
                    ></div>
                )}
                {!isBlank(topOffer.subline) && (
                    <div className="topoffer-subline">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: topOffer.subline,
                            }}
                        ></b>
                    </div>
                )}
                {topOffer.price !== 0 && (
                    <div
                        className="topoffer-price"
                        dangerouslySetInnerHTML={{
                            __html: __('from {{ displayPrice }} p.P.', 'dreamlines').replace(
                                '{{ displayPrice }}',
                                `<b>${getCurrencyString(topOffer.price, currency)}</b>`,
                            ),
                        }}
                    ></div>
                )}
                {!isBlank(topOffer.buttonText) && (
                    <div className="topoffer-actions">
                        <Button color="secondary">
                            <b
                                dangerouslySetInnerHTML={{
                                    __html: topOffer.buttonText,
                                }}
                            ></b>
                        </Button>
                    </div>
                )}
            </div>
        </SC_ExtraSearchComponent>
    );
};
