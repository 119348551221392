import styled from '@emotion/styled';
import React, { ReactNode, useRef, forwardRef, useImperativeHandle, Children } from 'react';
import Slider from 'react-slick';
import { BreakpointsEnum, BreakpointsEnumWithoutUnits } from '../../helpers/breakpoints';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon';
interface CardSlider {
    children: ReactNode;
    slidesToShow?: number;
    settings?: Record<null, unknown>;
}

export const CardSliderStyled = styled.div`
    position: relative;
    margin-bottom: 24px;
    margin-left: 16px;
    padding-bottom: 44px;
    @media (min-width: ${BreakpointsEnum.md}) {
        margin-left: 0;
        padding: 0;
    }
    .slick-track {
        display: flex;
        align-items: stretch;
    }
    .slick-prev,
    .slick-next {
        position: absolute;
        bottom: -45px;
    }
    .slick-prev {
        right: 64px;
    }
    .slick-next {
        right: 16px;
    }
    .slick-slide {
        margin-left: 16px;
        @media (min-width: ${BreakpointsEnum.sm}) {
            margin-left: 24px;
        }
        &:first-child {
            margin-left: 0;
        }
    }
    .slick-dots {
        list-style: none;
        text-align: left;
        position: absolute;
        bottom: -52px;
        right: 110px;
        left: 0;
        height: 32px;
        padding: 0;
        margin: 0;
        overflow: hidden;
        li {
            position: relative;
            display: inline-block;
            margin: 4px;
            padding: 0;
            width: 10px;
            height: 10px;
            cursor: pointer;
            button {
                font-size: 0;
                line-height: 0;
                display: block;
                padding: 0;
                color: transparent;
                border: none;
                outline: none;
                background: transparent;
                width: 10px;
                height: 10px;
                &:before {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: ${({ theme }) => theme.colors.white[100]};
                    border-radius: 100%;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        background-color: ${({ theme }) => theme.background.brandColor1};
                    }
                }
            }
        }
    }
`;

const CardSliderArrowButton = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: 100%;
    & > svg {
        fill: ${({ theme }) => theme.icon.brandColor1};
        width: 16px;
        height: 16px;
    }
`;

export const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <CardSliderArrowButton className={className} onClick={onClick}>
            <ArrowRightIcon />
        </CardSliderArrowButton>
    );
};

export const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <CardSliderArrowButton className={className} onClick={onClick}>
            <ArrowLeftIcon />
        </CardSliderArrowButton>
    );
};

const defaultConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    draggable: false,
    swipeToSlide: true,
    variableWidth: true,
    speed: 250,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: BreakpointsEnumWithoutUnits.xl,
            settings: {
                dots: false,
                arrows: false,
            },
        },
        {
            breakpoint: BreakpointsEnumWithoutUnits.lg,
            settings: {
                dots: true,
                arrows: true,
            },
        },
    ],
};

export const CardSlider = forwardRef((props: CardSlider, forwardedRef) => {
    const { children, slidesToShow, settings = null, ...restProps } = props;
    const settingsConfig = settings ? { ...defaultConfig, ...settings } : defaultConfig;
    settingsConfig.slidesToShow = slidesToShow || 1;
    const sliderRef = useRef(null);

    useImperativeHandle(forwardedRef, () => sliderRef.current as HTMLDivElement);

    return (
        <CardSliderStyled>
            <Slider ref={sliderRef} {...settingsConfig} {...restProps}>
                {children}
            </Slider>
        </CardSliderStyled>
    );
});
