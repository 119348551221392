import { deepClone } from './deepClone';

export const transformOptions = (
    initOption,
    data,
    topFilters = [],
    topFilterHeader = '',
    commonHeader = '',
) => {
    const facetOptions = data
        ?.filter((item) => item?.title)
        .map((val) => ({
            value: val.id,
            label: `${val.title} (${val.count})`,
            short_title: val.short_title,
            type: val?.type,
            className: val?.type,
        }));
    if (topFilters.length > 0) {
        const topFilterHeaderOption = { label: topFilterHeader, disabled: true };
        const commonHeaderOption = { label: commonHeader, disabled: true };
        const topOptions = topFilters
            .filter((val) => val)
            .map((val) => ({
                value: val.id,
                label: `${val.title} (${val.count})`,
                excludeFromSearch: true,
            }));
        return [initOption]
            .concat(topFilterHeaderOption)
            .concat(topOptions)
            .concat(commonHeaderOption)
            .concat(facetOptions);
    }
    return [initOption].concat(facetOptions);
};

export const getFilter = (search, filterDefaults) => {
    let state = deepClone(filterDefaults);
    const keys = Object.keys(state);
    const { filterParamsList } = search;
    keys.forEach((key) => {
        const param = filterParamsList.filter((_) => _.paramName === key).shift();
        if (param) {
            state[param.paramName] = Number(param.paramValue);
        }
    });
    return state;
};

export const setFilter = (stateKey: string, componentInstance) => {
    return (param: any) => {
        componentInstance.setState((state) => {
            let newState = {};
            newState[stateKey] = param;
            const nextState = { ...state, ...newState };
            return nextState;
        });
        componentInstance.props.setFilterParam([{ paramName: stateKey, paramValue: param }]);
    };
};

export const setFilterArray = (stateKeys: string[], componentInstance) => {
    return (params: any[]) => {
        let newState = {};
        const newParams = [];
        stateKeys.forEach((stateKey, index) => {
            const param = params[index];
            newState[stateKey] = param;
            newParams.push({ paramName: stateKey, paramValue: param });
        });
        componentInstance.props.setFilterParam(newParams);
        const nextState = { ...componentInstance.state, ...newState };
        componentInstance.setState(nextState);
    };
};

export const getLowestPrice = (cruises: any[]): number => {
    let lowestPrice = 0;

    if (!cruises?.length) return lowestPrice;

    const lowestCruise = cruises.reduce((prev, current) =>
        prev.cheapestSail.price < current.cheapestSail.price ? prev : current,
    );

    return lowestCruise ? lowestCruise.cheapestSail.price : lowestPrice;
};
