import * as React from 'react';
import * as Colors from '../../consts/variables';
import { TestSelector } from '../../helpers/TestSelector';
import { Button } from '../Button/Button';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        zIndex: 1,
    },
    stickyWrapper: {
        bottom: '0px',
        width: '100%',
        zIndex: 10,
        position: 'fixed',
        background: Colors.white,
        padding: '10px 15px',
    },
    grayBtn: {
        background: Colors.gray,
    },
    hideArrow: {
        display: 'none',
    },
};

interface DispatchProps {
    toggleSearchFilter?: Function;
    doNewSearchViaUrl: Function;
}
interface OwnProps {
    title: string;
    titleDesktop?: string;
    sticky?: boolean;
    fullWidth?: boolean;
    isLoading: boolean;
    isDisabled?: boolean;
    testSelectorName?: string;
    buttonClickEventData?: Record<string, string | number>;
}

type IProps = DispatchProps & OwnProps;

export class FilterFooter extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.applyFilter = this.applyFilter.bind(this);
    }

    static defaultProps = {
        sticky: true,
        fullWidth: true,
    };

    applyFilter() {
        if (this.props.buttonClickEventData) {
            eventTracker.trackG4Event('homepage_button_click', this.props.buttonClickEventData);
        }

        if (this.props.toggleSearchFilter) {
            this.props.toggleSearchFilter();
        }

        this.props.doNewSearchViaUrl();
    }

    render() {
        const wrapper = this.props.sticky
            ? { ...style.wrapper, ...style.stickyWrapper }
            : style.wrapper;

        const submitButton = this.props.title && (
            <Button
                fullWidth={this.props.fullWidth}
                withArrow
                disabled={this.props.isDisabled}
                loading={Boolean(this.props.isLoading)}
                onClick={this.applyFilter}
                testSelectorName={this.props.testSelectorName}
            >
                {this.props.title}
            </Button>
        );

        return (
            <div
                {...TestSelector.getSelector('searchFilterSubmit')}
                style={wrapper}
                className="searchPageSubmitButton"
            >
                {submitButton}
            </div>
        );
    }
}
