import React from 'react';
import styled from '@emotion/styled';
import { IResponsiveImage } from '../../reducers/homePage';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { css } from '@emotion/react';
import { blue } from '../../consts/variables';

interface ISearchMaskContainer {
    side?: string;
    hasDreamDeal: boolean;
    image?: IResponsiveImage;
    children?: React.ReactNode;
}

type IDivWithCoverBackground = {
    image?: IResponsiveImage;
    hasDreamDeal: boolean;
    side: string;
};

export const DivWithCoverBackground = styled.div<IDivWithCoverBackground>`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    background-size: cover;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: space-evenly;
    background-color: ${blue};
    background-image: url(${(props) => props.image?.mobile});

    ${(props) =>
        props.hasDreamDeal &&
        css`
            flex-direction: column;
            @media (min-width: ${BreakpointsEnum.sm}) {
                flex-direction: row;
            }
        `}

    @media (min-width: ${BreakpointsEnum.sm}) {
        ${(props) =>
            props.image?.mobile
                ? css`
                      height: 495px;
                  `
                : css`
                      .searchMask {
                          background-color: transparent !important;
                      }
                  `}
        background-image: url(${(props) => props.image?.desktop});
    }
    ${(props) =>
        props.hasDreamDeal &&
        props.side === 'left' &&
        css`
            flex-direction: row-reverse;
        `}
    ${(props) =>
        props.hasDreamDeal &&
        css`
            @media (min-width: ${BreakpointsEnum.md}) {
                justify-content: center;
                ${props.side === 'left'
                    ? css`
                          > div:not(.modalContainer) {
                              transform: translateX(-125px);
                          }
                      `
                    : css`
                          > div:not(.modalContainer) {
                              transform: translateX(125px);
                          }
                      `}
            }
            @media (min-width: ${BreakpointsEnum.lg}) {
                ${props.side === 'left'
                    ? css`
                          > div:not(.modalContainer) {
                              transform: translateX(-150px);
                          }
                      `
                    : css`
                          > div:not(.modalContainer) {
                              transform: translateX(150px);
                          }
                      `}
            }
        `}
    .dreamdeal-url,
    .topoffer-url {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        z-index: ${(props) => props.theme.overlayIndexes.topOffer};
    }

    .trust-badges {
        display: flex;
        padding: 16px 0;
        @media (min-width: ${BreakpointsEnum.sm}) {
            display: none;
        }
    }
`;

export const SearchMaskContainer = (props: ISearchMaskContainer) => {
    const { image, children, side, hasDreamDeal } = props;
    const componentSide = side || 'right';
    return (
        <DivWithCoverBackground hasDreamDeal={hasDreamDeal} side={componentSide} image={image}>
            {children}
        </DivWithCoverBackground>
    );
};
