import { __ } from './TranslationService';
import { isNull } from 'util';

export const getMonthTranslationKey = (month: number): string => {
    if (Number.isInteger(month) && month >= 0 && month <= 11) {
        return ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'][
            month
        ];
    }

    throw new Error(`Month is not valid (${month}) it should be 0 to 11`);
};

export const getTranslatedMonth = (month: number) =>
    __(getMonthTranslationKey(month), 'dreamlines');

export const isDateValid = (date: Date): boolean => !isNull(date) && !isNaN(date.getTime());

export const getAbsoluteUtcDate = (time: Date): number => {
    if (!time) return;
    // backend stores all dates in UTC
    // backend dosn't take into consideration hours
    // we need to hardcode hours so that it's not taken from local time

    return (
        new Date(Date.UTC(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0)).getTime() /
        1000
    );
};

// when searching backend treats all dates in UTC
// so we need to parse it as UTC date NOT in local timezone
export const parseAbsoluteUtcDate = (t) => {
    if (!t) {
        return null;
    }
    const time = new Date(t);
    return new Date(time.getUTCFullYear(), time.getUTCMonth(), time.getUTCDate());
};
