import * as React from 'react';
import { SlideEventTracker, slideStylesEnum } from '..';
import { getCurrencyString } from '../../../../components/PriceString';
import { __ } from '../../../../helpers/TranslationService';
import { ITeaser } from '../../../../reducers/homePage';
import * as S from './../styled';
interface IHomeGallerySlide {
    index: number;
    slide: ITeaser;
    galleryName: string;
    currency: string;
    slideStyle: string;
    buttonType?: string;
    eventTracker: ({ index, buttonType, url, label }: SlideEventTracker) => void;
    slideHasHeader: boolean;
    hasCompanyLogo: boolean;
    slideHasDescription: boolean;
}

export const HomeGallerySlide = (props: IHomeGallerySlide) => {
    const {
        galleryName,
        slide,
        slideStyle,
        slideHasHeader,
        hasCompanyLogo,
        slideHasDescription,
        currency,
        eventTracker,
        buttonType,
        index,
    } = props;

    const { name, companyLogo, label, text, image, description, url, price, discount } = slide;

    const innerTitle = {
        topTeasers: text,
        destinationGallery: name,
        themeCruiseTeasers: label,
        cruiseLineGallery: name,
    };

    return (
        <S.HomeSlide>
            <div onClick={() => eventTracker({ index, buttonType, url, label })}>
                <S.HomeSlideInner image={image} sliderStyle={slideStyle}>
                    {slideStyle === slideStylesEnum.special && slideHasHeader && label && (
                        <div
                            className="homeSlide__header"
                            dangerouslySetInnerHTML={{
                                __html: label,
                            }}
                        ></div>
                    )}
                    <h1 className="home-slide__inner-title">
                        <a
                            href={url}
                            className="home-slide__inner-title__link"
                            dangerouslySetInnerHTML={{
                                __html: innerTitle[galleryName] ?? '',
                            }}
                        ></a>
                        {slideStyle === slideStylesEnum.special && price && price > 0 ? (
                            <>
                                <br />
                                {__('pricePrefix', 'dreamlines')}
                                <span className="home-slide__inner-title__price">
                                    {` ${getCurrencyString(price, currency)}`}
                                </span>
                            </>
                        ) : null}
                    </h1>

                    {slideStyle === slideStylesEnum.regular && price && price > 0 ? (
                        <div className="home-slide__inner-price">
                            {discount > 0 && (
                                <div className="home-slide__inner-price__discount">
                                    - {discount}%
                                </div>
                            )}
                            <div
                                className={`home-slide__inner-price__per-person ${
                                    discount > 0 ? 'has_discount' : ''
                                }`}
                            >
                                <span>{__('pricePrefix', 'dreamlines')}</span>
                                <p>{getCurrencyString(price, currency)}</p>
                            </div>
                        </div>
                    ) : null}

                    {hasCompanyLogo && companyLogo && (
                        <S.HomeSlideInner_CompanyLogo
                            sliderStyle={slideStyle}
                            className="home-slide__company-logo"
                            image={companyLogo}
                        ></S.HomeSlideInner_CompanyLogo>
                    )}
                </S.HomeSlideInner>
                {slideHasDescription && (
                    <div
                        className="home-slide__description"
                        dangerouslySetInnerHTML={{
                            __html: description ?? text ?? '',
                        }}
                    ></div>
                )}
            </div>
        </S.HomeSlide>
    );
};
